import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import PortfolioContent from "../components/PortfolioContent"

// Display the title, date, and content of the Post
const PostBody = ({ data, pageContext, location, children }) => {
  
  const node = data.allPrismicPortfolioPiece.edges[0].node;
  const paginate = pageContext;
  
  const pieceName = location.pathname.replace('/portfolio', '').replace(/\//g, '').replace(/-/g, ' '); // remove dashes and slashes

  function capitalize(pieceName) { // capitalize the title for the title in the tab
    return pieceName.charAt(0).toUpperCase() + pieceName.slice(1);
  }

  const pageTitle = pieceName.split(' ').map(capitalize).join(' ');
  
  if (!node && !pageContext) return null


  return (
    <>
      <Helmet
        title={ `${data.site.siteMetadata.title} - ${pageTitle}` }
      />
      <div className="portfolio-piece-page">
        <PortfolioContent post={node} paginate={paginate} />
      </div>
    </>
  )
}

export const query = graphql`
  query($uid: String) {
    allPrismicPortfolioPiece(filter: { slugs: { eq: $uid } }) {
      edges {
        node {
          uid
          data {
            portfolio_desc {
              ... on PrismicPortfolioPieceDataPortfolio_desc {
                type
                text
                spans {
                  start
                  end
                  type
                }
              }
            }
            portfolio_background
            portfolio_title {
              type
              text
            }
            portfolio_image {
              url
            }
            portfolio_web_link {
              link_type
              url
            }
            portfolio_copy {
              type
              text
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        description
        title
        author
        backgrounds {
          black
          normal
          transparent
        }
      }
    }
  }
`

export default PostBody
