import React, { useEffect } from "react"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"

const PortfolioContent = ({ post, paginate }) => {
  const piece = post.data;
  
  useEffect(() => {
    document.body.style.background = `${piece.portfolio_background}`
  })

  return (
    <div>
      <h1>{piece.portfolio_title[0].text}</h1>
      <div className="headline">
        <div className="copy">
          <RichText render={piece.portfolio_desc} />
        </div>
        <a href={ piece.portfolio_web_link.url } className="piece-link">
          <img src={piece.portfolio_image.url} />
        </a>
      </div>
      <div className="piece-copy">
        <p>{piece.portfolio_copy[0].text}</p>
      </div>
      <div className="pagination">
        <div className="pagination-item prev">
          {paginate.prev &&  (
            <>
                <Link className="pagination-link" to={`/portfolio/${paginate.prev.uid}`}>
                    <p>{paginate.prev.data.portfolio_title[0].text}</p>
                </Link>
                <span className="pagination-image" style={{ backgroundImage: `url(${paginate.prev.data.portfolio_image.url})`}}>
                    <span className="frost" style={{ backgroundColor: `${ paginate.prev.data.portfolio_background }` }}></span>
                </span>
            </>
          )}
        </div>
        <div className="pagination-item next">
          {paginate.next && (
            <>
                <Link className="pagination-link" to={`/portfolio/${paginate.next.uid}`}>
                    <p>{paginate.next.data.portfolio_title[0].text}</p>
                </Link>
                <span className="pagination-image" style={{ backgroundImage: `url(${paginate.next.data.portfolio_image.url})`}}>
                    <span className="frost" style={{ backgroundColor: `${ paginate.next.data.portfolio_background }` }}></span>
                </span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default PortfolioContent
